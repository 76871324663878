"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const operators_1 = require("rxjs/operators");
const zodiacService_1 = require("@games/zodiacsigns/src/zodiacService");
function transpose(matrix) {
    return matrix[0].map((col, i) => matrix.map(row => row[i]));
}
class ZodiacsignsCtrl {
    constructor($scope, $timeout, $filter, $location, ConfigService, ZodiacService) {
        var _a;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.$location = $location;
        this.ConfigService = ConfigService;
        this.ZodiacService = ZodiacService;
        // kind: IKind = this.$location.search().compatibility ? 'compatibility' : (localStorage.getItem(`${this.constructor.name}_kind`) as IKind || 'dates')
        this.kind = this.$location.search().compatibility ? 'compatibility' : 'dates';
        this.chinaZodiacs = ng.copy(this.ZodiacService.chinaZodiacs);
        this.zodiacs = ng.copy(this.ZodiacService.zodiacs);
        this.graphs = [
            {
                title: this.$filter('translate')('Overall'),
                data: [
                    [50, 38, 83, 42, 97, 63, 85, 50, 93, 47, 78, 67],
                    [38, 65, 33, 97, 73, 90, 65, 88, 30, 98, 58, 85],
                    [83, 33, 60, 65, 88, 68, 93, 28, 60, 68, 85, 53],
                    [42, 97, 65, 75, 35, 90, 43, 94, 53, 83, 27, 98],
                    [97, 73, 88, 35, 45, 35, 97, 58, 93, 35, 68, 38],
                    [63, 90, 68, 90, 35, 65, 68, 88, 48, 95, 30, 88],
                    [85, 65, 93, 43, 97, 68, 75, 35, 73, 55, 90, 88],
                    [50, 88, 28, 94, 58, 88, 35, 80, 28, 95, 73, 97],
                    [93, 30, 60, 53, 93, 48, 73, 28, 45, 60, 90, 63],
                    [47, 98, 68, 83, 35, 95, 55, 95, 60, 75, 68, 88],
                    [78, 58, 85, 27, 68, 30, 90, 73, 90, 68, 45, 45],
                    [67, 85, 53, 98, 38, 88, 88, 97, 63, 88, 45, 60],
                ]
            },
            {
                title: this.$filter('translate')('Sex'),
                data: [
                    [75, 43, 75, 70, 100, 35, 95, 70, 100, 60, 70, 70],
                    [43, 90, 53, 100, 88, 95, 73, 100, 38, 98, 60, 98],
                    [75, 53, 90, 63, 83, 53, 100, 20, 70, 58, 94, 73],
                    [70, 100, 63, 85, 28, 85, 73, 100, 37, 93, 18, 100],
                    [100, 88, 83, 28, 65, 17, 95, 83, 100, 48, 85, 55],
                    [35, 95, 53, 85, 17, 50, 58, 73, 70, 96, 20, 95],
                    [95, 73, 100, 73, 95, 58, 65, 50, 95, 80, 95, 70],
                    [70, 100, 20, 100, 83, 73, 50, 90, 33, 100, 80, 100],
                    [100, 38, 70, 37, 100, 70, 95, 33, 80, 70, 95, 75],
                    [60, 98, 58, 93, 48, 96, 80, 100, 70, 90, 48, 90],
                    [70, 60, 94, 18, 85, 20, 95, 80, 95, 48, 40, 30],
                    [70, 98, 73, 100, 55, 95, 70, 100, 75, 90, 30, 80],
                ]
            },
            {
                title: this.$filter('translate')('Love'),
                data: [
                    [40, 43, 85, 49, 96, 55, 84, 46, 85, 46, 78, 46],
                    [43, 65, 33, 96, 70, 86, 61, 81, 33, 95, 55, 81],
                    [85, 33, 58, 58, 84, 69, 90, 36, 68, 66, 86, 55],
                    [49, 96, 58, 80, 46, 85, 49, 90, 46, 79, 34, 98],
                    [96, 70, 84, 46, 50, 55, 99, 61, 89, 39, 69, 43],
                    [55, 86, 69, 85, 55, 63, 68, 88, 53, 95, 40, 88],
                    [84, 61, 90, 49, 99, 68, 78, 41, 76, 55, 94, 74],
                    [46, 81, 36, 90, 61, 88, 41, 83, 38, 93, 71, 96],
                    [85, 33, 68, 46, 89, 53, 76, 38, 50, 60, 88, 59],
                    [46, 95, 66, 79, 39, 95, 55, 93, 60, 73, 66, 85],
                    [78, 55, 86, 34, 69, 40, 94, 71, 88, 66, 53, 50],
                    [46, 81, 55, 98, 43, 88, 74, 96, 59, 85, 50, 58],
                ]
            },
            {
                title: this.$filter('translate')('Communication'),
                data: [
                    [35, 30, 80, 30, 90, 75, 70, 60, 95, 55, 85, 65],
                    [30, 50, 25, 98, 75, 90, 55, 85, 25, 100, 60, 85],
                    [80, 25, 80, 70, 95, 75, 90, 25, 55, 75, 80, 50],
                    [30, 98, 70, 70, 30, 95, 35, 95, 60, 90, 30, 99],
                    [90, 75, 95, 30, 40, 45, 95, 55, 95, 30, 55, 30],
                    [75, 90, 75, 95, 45, 75, 70, 95, 40, 93, 35, 85],
                    [70, 55, 90, 35, 95, 70, 80, 20, 65, 40, 97, 80],
                    [60, 85, 25, 95, 55, 95, 20, 75, 20, 97, 75, 95],
                    [95, 25, 55, 60, 95, 40, 65, 20, 40, 55, 95, 60],
                    [55, 100, 75, 90, 30, 93, 40, 97, 55, 65, 75, 85],
                    [85, 60, 80, 30, 55, 35, 97, 75, 95, 75, 50, 40],
                    [65, 85, 50, 99, 30, 85, 80, 95, 60, 85, 40, 65],
                ]
            },
            {
                title: this.$filter('translate')('Friendship'),
                data: [
                    [70, 55, 95, 45, 95, 78, 70, 50, 98, 45, 100, 67],
                    [55, 75, 30, 98, 70, 95, 55, 80, 40, 98, 65, 85],
                    [95, 30, 70, 75, 95, 70, 95, 35, 70, 75, 100, 65],
                    [45, 98, 75, 75, 45, 98, 40, 95, 65, 75, 25, 100],
                    [95, 70, 95, 45, 50, 25, 95, 65, 95, 30, 65, 30],
                    [78, 95, 70, 98, 25, 70, 75, 90, 55, 100, 36, 85],
                    [70, 55, 95, 40, 95, 75, 75, 45, 80, 60, 95, 80],
                    [50, 80, 35, 95, 65, 90, 45, 80, 35, 100, 80, 98],
                    [98, 40, 70, 65, 95, 55, 80, 35, 60, 75, 98, 65],
                    [45, 98, 75, 75, 30, 100, 60, 100, 75, 75, 70, 100],
                    [100, 65, 100, 25, 65, 36, 95, 80, 98, 70, 55, 40],
                    [67, 85, 65, 100, 30, 85, 80, 98, 65, 100, 40, 80],
                ]
            },
        ];
        this.graph = 0;
        this.now = new Date();
        this.errors = [];
        const year = this.now.getFullYear();
        this.zodiacs.forEach((item) => {
            let toYear = year;
            if (item.from.month > item.to.month) {
                toYear += 1;
            }
            const from = new Date(year, item.from.month, item.from.date);
            const to = new Date(toYear, item.to.month, item.to.date);
            if ((this.now >= from) && (this.now <= to)) {
                item.current = true;
            }
        });
        this.chinaZodiacs.forEach((item) => {
            const dates = item.dates[0];
            const from = dates[0];
            const to = dates[1];
            if ((this.now >= from) && (this.now <= to)) {
                //@ts-ignore
                item.current = true;
            }
        });
        this.$scope.$watch(() => {
            return this.$location.search();
        }, (value) => {
            if (value.compatibility) {
                const compatibility = parseInt(value.compatibility);
                if (ng.isNumber(compatibility)) {
                    this.setZodiac(compatibility);
                    this.setKind('compatibility');
                }
                else {
                    this.setKind(this.kind);
                }
            }
            else {
                this.setKind(this.kind);
            }
        });
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            // localStorage.removeItem(`${this.constructor.name}_zodiacIndex`)
            // localStorage.removeItem(`${this.constructor.name}_kind`)
            window.location.href = currentTarget.href;
        })).subscribe();
    }
    $onInit() {
    }
    setKind(kind) {
        this.kind = kind;
        if (this.kind != 'compatibility') {
            this.$location.search('compatibility', null);
            this.zodiac = undefined;
            this.zodiacIndex = undefined;
        }
        else {
            if (!this.zodiac) {
                this.setZodiac(0);
            }
        }
    }
    setZodiac(index) {
        this.zodiac = this.zodiacs[index];
        this.$location.search('compatibility', index);
        this.zodiacIndex = index;
        // localStorage.setItem(`${this.constructor.name}_zodiacIndex`, index.toString())
    }
    formatDateZodiac(zodiac) {
        const from = new Date(this.now.getFullYear(), zodiac.from.month, zodiac.from.date);
        const to = new Date(this.now.getFullYear(), zodiac.to.month, zodiac.to.date);
        let fromString = this.$filter('date')(from, 'd MMMM');
        let toString = this.$filter('date')(to, 'd MMMM');
        return `${fromString} – ${toString}`;
    }
    checkGraph() {
        // for debug
        const errors = [];
        const transposeGraph = transpose(this.graphs[this.graph].data);
        this.graphs[this.graph].data.forEach((item, rowIndex) => {
            item.map((percent, colIndex) => {
                if (percent != transposeGraph[rowIndex][colIndex])
                    errors.push([rowIndex, colIndex]);
            });
        });
        this.errors = errors;
        return errors;
    }
    checkCell(rowIndex, colIndex) {
        return this.errors.some((item) => {
            return (item[0] == rowIndex && item[1] == colIndex);
        });
    }
}
ZodiacsignsCtrl.$inject = ['$scope', '$timeout', '$filter', '$location', 'ConfigService', 'ZodiacService'];
const appModule = ng.module('app');
appModule.service('ZodiacService', zodiacService_1.ZodiacService);
appModule.component('gameZodiacsigns', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: ZodiacsignsCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('zodiacsigns/');
    }]);
